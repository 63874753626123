<template>
    <section class="footer">
        <div class="map_wrapper">
            <GMapMap id="map" :center="center" :zoom="16" map-type-id="roadmap">
                <GMapMarker
                    :key="marker.id"
                    v-for="marker in markers"
                    :position="marker.position"
                />
            </GMapMap>
        </div>
        <div class="contact_wrapper">
            <h2>Yhteystiedot</h2>
            <div class="contact_info">
                <ul>
                    <li v-if="contact.phone">
                        <img class="icon" :src="Phone" alt="phone" /><span>{{
                            contact.phone
                        }}</span>
                    </li>
                    <li v-if="contact.address">
                        <img class="icon" :src="Address" alt="address" /><span>{{
                            contact.address
                        }}</span>
                    </li>
                    <li v-if="contact.email">
                        <img class="icon" :src="Email" alt="email" /><span>{{
                            contact.email
                        }}</span>
                    </li>
                </ul>
            </div>
            <img :src="Oiva" @click="report" alt="Oiva" class="footer_image" />
        </div>
    </section>
</template>
<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import Oiva from '@/assets/svg/oiva.svg'
import Phone from '@/assets/svg/phone.svg'
import Email from '@/assets/svg/email.svg'
import Address from '@/assets/svg/address.svg'
export default defineComponent({
    name: 'Footer',
    components: {},
    props: ['contact'],
    setup() {
        const center = { lat: 60.22210713584259, lng: 24.81348467003291 }
        const markers = [
            {
                id: 'TungHing',
                position: {
                    lat: 60.22210713584259,
                    lng: 24.81348467003291,
                },
            },
        ]
	const report = () => window.open("https://cms.tunghing.fi/uploads/oiva_bc4adda046.pdf")
        return { center, markers, Oiva, Phone, Email, Address, report }
    },
})
</script>
<style lang="scss">
.footer {
    background: $dark_blue;
    min-height: 500px;
    display: flex;
    flex-wrap: wrap;
    .map_wrapper {
        flex: 60%;
        min-width: 300px;
        .vue-map,
        .vue-map-container {
            height: 100%;
        }
    }
    .contact_wrapper {
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-width: 300px;
        flex: 40%;
        .contact_info {
            margin-bottom: 60px;
            display: flex;
            justify-content: center;
            ul {
                text-align: left;
                list-style-type: none;
                li {
                    span {
                        vertical-align: middle;
                        margin-left: 10px;
                    }
                    .icon {
                        vertical-align: middle;
                        max-width: 22px;
                    }
                }
            }
            color: #fff;
        }
        .footer_image {
            max-width: 150px;
        }
    }
}
@media only screen and (max-width: 770px) {
    .footer {
        min-height: 800px;
        .contact_wrapper {
            .contact_info {
                margin-bottom: 0px;
            }
        }
    }
}
</style>
