
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'MenuList',
    components: {},
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    setup({ items }) {
        return {}
    },
})
