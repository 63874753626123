<template>
    <section class="wrapper">
        <div class="lunch">
            <h1>Lounas</h1>
            <h3 class="red">Menu</h3>

            <div class="lunch_buttons">
                <button
                    class="btn bold outlined small"
                    v-for="(menu, i) in lunchMenu"
                    :key="i"
                    @click="setDate(menu.day_fi)"
                >
                    {{ menu.day_fi }}
                </button>
            </div>

            <MenuList :items="currentMenu.items" v-if="currentMenu && currentMenu.items.length" />
            <div v-if="!currentMenu || !currentMenu.items.length" class="menu_info">
                <h3>{{ today }}sin ei ole lounasta</h3>
                <br />
            </div>
        </div>
        <div class="hours">
            <div class="hours_wrapper">
                <h1>Aukioloajat</h1>
                <img :src="clock" alt="clock" class="clock" />
                <div class="hours_list" v-for="(item, i) in businessHours" :key="`item-${i}`">
                    <ul>
                        <div class="day">{{ item.day_fi }}</div>
                        <template v-if="!item.closed">
                            <li class="bold">{{ item.open }} - {{ item.close }}</li>
                        </template>
                        <template v-else>
                            <li class="bold light_red">Suljettu</li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, reactive } from 'vue'
import MenuList from '@/components/MenuList.vue'
import clock from '@/assets/svg/clock.svg'
import moment from 'moment'
moment.locale('fi')
export default defineComponent({
    name: 'Lunch',
    components: { MenuList },
    props: ['lunch', 'hours'],
    setup({ lunch, hours }) {
        const state = reactive({
            selectedDay: '',
        })
        const today = moment().format('dddd')
        const lunchMenu = computed(() => lunch.filter((menu: any) => menu.items.length))
        const currentMenu = computed(() =>
            lunch.find((menu: any) => menu.day_fi.toLowerCase() == state.selectedDay.toLowerCase())
        )
        const businessHours = computed(() => hours)
        const setDate = (date: string) => {
            state.selectedDay = date
        }

        const selected = computed(() => state.selectedDay)

        onMounted(() => {
            state.selectedDay = today
        })
        return {
            lunchMenu,
            businessHours,
            setDate,
            selected,
            currentMenu,
            lunch,
            today,
            clock,
        }
    },
})
</script>
<style lang="scss">
.wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px;
    .lunch {
        padding-top: 50px;
        padding-bottom: 50px;
        background: $grey;
        flex: 50%;
        min-width: 350px;
        .menu_info {
            padding-top: 50px;
        }
        .lunch_buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            .lunch_menu_btn {
                min-width: 15vh;
                max-width: 20vw;
                padding: 10px;
                margin: 10px;
                flex: 1;
            }
        }
    }
    .hours {
        padding-top: 50px;
        padding-bottom: 50px;
        flex: 50%;
        min-width: 350px;
        background: $dark_blue;
        color: white;
        .hours_wrapper {
            .clock {
                width: 2vw;
                max-width: 120px;
                min-width: 50px;
            }
            .hours_list {
                ul {
                    padding: 5px;
                    .day {
                        margin-bottom: 10px;
                    }
                    margin-bottom: 10px;
                }
                li {
                    list-style-type: none;
                }
                .hours_item {
                    text-align: left;
                    line-height: 2em;
                    padding: 5px;
                }
            }
        }
    }
}
</style>
