<template>
    <div>
        <img :src="imgUrl" alt="Food image" />
        <div class="image_text"></div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ImageBox',
    props: ['url'],
    components: {},
    setup({ url }) {
        const imgUrl =
            process.env.NODE_ENV == 'production'
                ? `${process.env.VUE_APP_PROD_URL}${url}`
                : `${process.env.VUE_APP_DEV_URL}${url}`
        return {
            imgUrl,
        }
    },
})
</script>
<style lang="scss">
.image_container {
    //  flex: 33.33%;
    overflow: hidden;
    max-height: 500px;
    background: $dark_blue;
    position: relative;
    min-width: 350px;
    min-height: 400px;
    .image_text {
        color: white;
        position: absolute;
        left: 50%; /* horizontal alignment */
        top: 50%; /* vertical alignment */
        transform: translate(-50%, -50%); /* precise centering; see link below */
    }
    img {
        -webkit-font-smoothing: antialiased;
        -webkit-transition: all 0.65s;
        -moz-transition: all 0.65s;
        -o-transition: all 0.65s;
        transition: all 0.65s;
        min-width: 300px;
        object-fit: cover;
        opacity: 0.3;
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        &:hover {
            opacity: 0.6;
        }
    }
}
</style>
