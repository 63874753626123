<template>
    <section class="advert">
        <div class="advert_box shadow">
            <div class="icon_box">
                <img class="icon" :src="TakeAway" alt="" />
            </div>
            <div class="advert_box_content">
                <h1 class="bold">Take away <span>-10%</span></h1>
            </div>
        </div>
    </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import TakeAway from '@/assets/svg/takeaway.svg'
export default defineComponent({
    name: 'Advert',
    components: {},
    setup() {
        return { TakeAway }
    },
})
</script>
<style lang="scss">
.advert {
    display: flex;
    justify-content: center;
    margin: -50px 10px;
    .advert_box {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background: #fff;
        z-index: 20;
        border-radius: 26px;
        width: 350px;
        height: 100px;
        transition: 0.25s;
        .icon_box {
            display: flex;
            justify-content: center;
            border-radius: 26px;
            background: $primary;
            width: 80px;
            height: 80px;
            margin-right: 20px;
            padding: 10px;
            .icon {
                max-width: 50px;
            }
        }
    }
    @media only screen and (max-width: 390px) {
        .advert_box {
            font-size: 12px;
            flex-shrink: 0;
            width: 280px;
        }
    }
}
</style>
