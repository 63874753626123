import axios from 'axios'

const baseURL =
    (process.env.NODE_ENV as string) == 'development'
        ? process.env.VUE_APP_DEV_URL
        : process.env.VUE_APP_PROD_URL

const api = axios.create({
    baseURL,
})

export default api
