
import { defineComponent } from 'vue'
import Food_square from '@/assets/img/food_square.png'
export default defineComponent({
    name: 'Info',
    components: {},
    props: ['story'],
    setup({ story }) {
        return {
            Food_square,
        }
    },
})
