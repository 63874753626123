<template>
    <div class="menu" ref="MenuList">
        <transition-group name="list-complete" appear>
            <li v-for="item in items" :key="item" class="item list-complete-item">
                {{ item.name_fi }}
                <hr />
            </li>
        </transition-group>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'MenuList',
    components: {},
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    setup({ items }) {
        return {}
    },
})
</script>
<style lang="scss">
.menu {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 20px;
    overflow: hidden;
    transition: height 0.6s ease-out;
    .item {
        margin: 1em;
    }
}
</style>
