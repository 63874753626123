
import { defineComponent, onMounted } from 'vue'
import Oiva from '@/assets/svg/oiva.svg'
import Phone from '@/assets/svg/phone.svg'
import Email from '@/assets/svg/email.svg'
import Address from '@/assets/svg/address.svg'
export default defineComponent({
    name: 'Footer',
    components: {},
    props: ['contact'],
    setup() {
        const center = { lat: 60.22210713584259, lng: 24.81348467003291 }
        const markers = [
            {
                id: 'TungHing',
                position: {
                    lat: 60.22210713584259,
                    lng: 24.81348467003291,
                },
            },
        ]
	const report = () => window.open("https://cms.tunghing.fi/uploads/oiva_bc4adda046.pdf")
        return { center, markers, Oiva, Phone, Email, Address, report }
    },
})
