import api from '@/services/api'

export const getBusinessHours = async () => {
    const { data } = await api.get('/hours')
    return data
}
export const getLunch = async () => {
    const { data } = await api.get('/lunches')
    return data
}
export const getImages = async () => {
    const { data } = await api.get('/images')
    console.log(data)
    return data
}
export const getMenus = async () => {
    const { data } = await api.get('/menus')
    return data
}
export const getStory = async () => {
    const { data } = await api.get('/story')
    return data
}
export const getContact = async () => {
    const { data } = await api.get('/contact')
    return data
}
