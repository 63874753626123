
import { computed, defineComponent, onMounted, reactive } from 'vue'
import MenuList from '@/components/MenuList.vue'
import clock from '@/assets/svg/clock.svg'
import moment from 'moment'
moment.locale('fi')
export default defineComponent({
    name: 'Lunch',
    components: { MenuList },
    props: ['lunch', 'hours'],
    setup({ lunch, hours }) {
        const state = reactive({
            selectedDay: '',
        })
        const today = moment().format('dddd')
        const lunchMenu = computed(() => lunch.filter((menu: any) => menu.items.length))
        const currentMenu = computed(() =>
            lunch.find((menu: any) => menu.day_fi.toLowerCase() == state.selectedDay.toLowerCase())
        )
        const businessHours = computed(() => hours)
        const setDate = (date: string) => {
            state.selectedDay = date
        }

        const selected = computed(() => state.selectedDay)

        onMounted(() => {
            state.selectedDay = today
        })
        return {
            lunchMenu,
            businessHours,
            setDate,
            selected,
            currentMenu,
            lunch,
            today,
            clock,
        }
    },
})
