<template>
    <section class="services">
        <div class="service_box" v-for="(service, i) in services" :key="i">
            <img
                class="service__image"
                @click="navigate(service.link)"
                :src="service.icon"
                :alt="service.name"
            />
        </div>
    </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import Foodora from '@/assets/svg/Foodora.svg'
import Wolt from '@/assets/svg/Wolt.svg'
export default defineComponent({
    name: 'Services',
    components: {},
    setup() {
        const services = [
            {
                icon: Foodora,
                name: 'Foodora',
                link: 'https://www.foodora.fi/en/restaurant/s5xs/tung-hing',
            },
            {
                icon: Wolt,
                name: 'Wolt',
                link: 'https://wolt.com/fi/fin/espoo/restaurant/ravintola-tung-hing',
            },
        ]
        const navigate = (link: string) => (window.location.href = link)
        return { services, navigate }
    },
})
</script>
<style lang="scss">
.services {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 150px;
    .service_box {
        max-width: 300px;
        padding: 20px;
        flex: 1;
        .service__image {
            cursor: pointer;
            min-width: 150px;
        }
    }
}
</style>
