<template>
    <section class="info">
        <div class="info_content">
            <img class="square" :src="Food_square" alt="food_square" />
        </div>
        <div class="info_content">
            <h1>Ravintola</h1>
            <h3 class="red">Tarinamme</h3>
            <p class="story" v-html="story"></p>
        </div>
    </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import Food_square from '@/assets/img/food_square.png'
export default defineComponent({
    name: 'Info',
    components: {},
    props: ['story'],
    setup({ story }) {
        return {
            Food_square,
        }
    },
})
</script>
<style lang="scss">
.info {
    display: flex;
    padding-bottom: 50px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .info_content {
        h1,
        h3,
        p {
            text-align: left;
            letter-spacing: 0.15px;
        }
        h1 {
            margin-bottom: 0px;
        }
        h3 {
            margin-top: 0px;
        }
        p {
            line-height: 2em;
        }
        .story {
            white-space: pre-line;
        }
        img {
            width: 100%;
            max-width: 480px;
        }
        padding: 20px;
        flex: 1;
        min-width: 300px;
        max-width: 600px;
    }
}
</style>
