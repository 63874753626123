
import { defineComponent } from 'vue'
import HeaderSvg from '@/assets/img/headerbg.png'
import Logo from '@/assets/img/logo.png'
import dumpling_1 from '@/assets/svg/001-dumpling.svg'
import noodles from '@/assets/svg/002-noodles.svg'
import rice from '@/assets/svg/003-rice.svg'
import tea from '@/assets/svg/004-tea.svg'
import shrimp from '@/assets/svg/006-shrimp.svg'
export default defineComponent({
    name: 'Header',
    components: {},
    setup() {
        return {
            Logo,
            HeaderSvg,
            dumpling_1,
            noodles,
            rice,
            tea,
            shrimp,
        }
    },
})
