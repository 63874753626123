
import { defineComponent, computed } from 'vue'
import ImageBox from '@/components/ImageBox.vue'
export default defineComponent({
    name: 'Images',
    components: { ImageBox },
    props: ['images'],
    setup({ images }) {
        const style = computed(() => {
            return {
                flex: `${100 / images.length}%`,
            }
        })
        return {
            images,
            style,
        }
    },
})
