
import { defineComponent } from 'vue'
import TakeAway from '@/assets/svg/takeaway.svg'
export default defineComponent({
    name: 'Advert',
    components: {},
    setup() {
        return { TakeAway }
    },
})
