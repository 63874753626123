<template>
    <section class="images">
        <ImageBox
            v-for="(image, i) in images"
            :key="`image-${i}`"
            :url="image.food.url"
            class="image_container"
            :style="style"
        />
    </section>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue'
import ImageBox from '@/components/ImageBox.vue'
export default defineComponent({
    name: 'Images',
    components: { ImageBox },
    props: ['images'],
    setup({ images }) {
        const style = computed(() => {
            return {
                flex: `${100 / images.length}%`,
            }
        })
        return {
            images,
            style,
        }
    },
})
</script>
<style lang="scss">
.images {
    display: flex;
    flex-wrap: wrap;
    background: white;
}
</style>
