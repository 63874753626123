<template>
    <section class="header">
        <div class="intro">
            <img class="logo" :src="Logo" />
            <h1>Kiinalainen ravintola Espoon Leppävaarassa</h1>
            <h3 class="light">Vuodesta 1993</h3>
            <div class="hr" />
            <p class="light">Tule nauttimaan Kiinalaisesta ruoasta!</p>
            <button class="bold btn">Menu</button>
        </div>
        <img class="dumpling_1" :src="dumpling_1" />
        <img class="noodles" :src="noodles" />
        <img class="rice" :src="rice" />
        <img class="tea" :src="tea" />
        <img class="shrimp" :src="shrimp" />
    </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import HeaderSvg from '@/assets/img/headerbg.png'
import Logo from '@/assets/img/logo.png'
import dumpling_1 from '@/assets/svg/001-dumpling.svg'
import noodles from '@/assets/svg/002-noodles.svg'
import rice from '@/assets/svg/003-rice.svg'
import tea from '@/assets/svg/004-tea.svg'
import shrimp from '@/assets/svg/006-shrimp.svg'
export default defineComponent({
    name: 'Header',
    components: {},
    setup() {
        return {
            Logo,
            HeaderSvg,
            dumpling_1,
            noodles,
            rice,
            tea,
            shrimp,
        }
    },
})
</script>
<style lang="scss">
.header {
    width: 100%;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
    padding-bottom: 150px;
    background: url(../assets/img/headerbg.png) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: #fff;
    position: relative;
    h1,
    h3 .logo {
        z-index: 10;
        position: relative;
    }
    .floating_icon {
        position: absolute;
        width: 5vw;
        max-width: 120px;
        min-width: 50px;
        z-index: 1 !important;
        transition: 0.25s;
    }
    @media only screen and (max-width: 800px) {
        .floating_icon {
            opacity: 0.2;
        }
    }
    .dumpling_1 {
        @extend .floating_icon;
        left: 10%;
        top: 15%;
    }
    .noodles {
        @extend .floating_icon;
        right: 22%;
        top: 10%;
        transform: rotate(20deg);
    }
    .rice {
        @extend .floating_icon;
        right: 20%;
        bottom: 30%;
        transform: rotate(-20deg);
    }
    .tea {
        @extend .floating_icon;
        left: 26%;
        bottom: 10%;
        transform: rotate(20deg);
    }
    .shrimp {
        @extend .floating_icon;
        left: 10%;
        bottom: 40%;
        transform: rotate(20deg);
    }
    .logo {
        width: 80%;
        max-width: 700px;
    }
}
</style>
