<template>
    <transition-group name="fade" appear>
        <div class="loading" v-if="loading" key="loading">
            <div class="loading_wrapper">
                <div class="loader"></div>
                <h1>Ladataan...</h1>
            </div>
        </div>
        <div class="home" v-else key="home">
            <Header />
            <Advert />
            <Services />
            <Info :story="story" />
            <Lunch :lunch="lunchMenu" :hours="businessHours" />
            <Images :images="images" />
            <Menu :menus="menuItems" />
            <Footer :contact="contact" />
        </div>
    </transition-group>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref, onBeforeMount } from 'vue'
import Header from '@/components/Header.vue'
import Advert from '@/components/Advert.vue'
import Services from '@/components/Services.vue'
import Info from '@/components/Info.vue'
import Lunch from '@/components/Lunch.vue'
import Images from '@/components/Images.vue'
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'

import {
    getBusinessHours,
    getLunch,
    getImages,
    getMenus,
    getStory,
    getContact,
} from '@/services/common'
export default defineComponent({
    name: 'Home',
    components: {
        Header,
        Advert,
        Services,
        Info,
        Lunch,
        Images,
        Menu,
        Footer,
    },
    setup() {
        let loading = ref(true)
        let state = reactive({
            lunchItems: [],
            hours: [],
            images: [],
            menus: [],
            story: {},
            contact: {},
        })
        const lunchMenu = computed(() => state.lunchItems)
        const businessHours = computed(() => state.hours)
        const images = computed(() => state.images)
        const menuItems = computed(() => state.menus)
        const story = computed(() => state.story)
        const contact = computed(() => state.contact)
        onBeforeMount(async () => {
            loading.value = true
            try {
                const lunch = await getLunch()
                const hours = await getBusinessHours()
                const images = await getImages()
                const menus = await getMenus()
                const story = await getStory()
                const contact = await getContact()

                state.lunchItems = lunch
                state.hours = hours
                state.images = images
                state.menus = menus
                state.story = story.story
                state.contact = contact
                loading.value = false
            } catch (error) {
                console.log('Fetching error ', error)
                loading.value = false
            }
        })

        return {
            loading,
            lunchMenu,
            businessHours,
            images,
            menuItems,
            story,
            contact,
        }
    },
})
</script>
<style lang="scss">
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
}
.loading_wrapper {
    align-self: center;
    text-align: center;
    width: 300px;
    height: 300px;
}
.loader {
    display: block;
    margin: auto;
    border: 6px solid #f3f3f3;
    border-top: 6px solid $primary;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
