<template>
    <section class="menu">
        <div class="menu_header">
            <h1>Menu</h1>
            <h3 class="red">A la carte</h3>
            <div class="menu_buttons">
                <button
                    class="btn bold outlined small"
                    v-for="(menu, i) in menus"
                    :key="i"
                    @click="setMenu(menu.id)"
                >
                    {{ menu.name_fi }}
                </button>
            </div>
        </div>
        <div class="menu_items" v-if="currentMenu">
            <transition-group name="list-complete" appear tag="ul">
                <li
                    v-for="item in currentMenu.items"
                    :key="item.id"
                    class="item list-complete-item"
                >
                    <div class="item_wrapper">
                        <img v-if="item.spicy" :src="Chili" alt="spicy" class="spicy" />
                        <div class="name_fi bold">
                            <span v-if="item.chefsSpecial">S.</span>{{ item.number }}.
                            {{ item.name_fi }}
                        </div>
                        <div class="name_en">{{ item.name_en }}</div>
                        <div class="linewrapper">
                            <div>
                                Hinta
                            </div>
                            <div class="middle"></div>
                            <div>{{ item.price }}€</div>
                        </div>
                    </div>
                </li>
            </transition-group>
        </div>
    </section>
</template>
<script lang="ts">
import { defineComponent, computed, reactive, onMounted } from 'vue'
import Chili from '@/assets/svg/chili.svg'
export default defineComponent({
    name: 'Menu',
    components: {},
    props: ['menus'],
    setup({ menus }) {
        const state = reactive({
            activeMenuId: '',
        })
        const currentMenu = computed(() => menus.find((menu: any) => menu.id == state.activeMenuId))
        const setMenu = (id: string) => {
            state.activeMenuId = id
        }
        onMounted(() => (state.activeMenuId = menus[0].id))

        return { setMenu, menus, currentMenu, Chili }
    },
})
</script>
<style lang="scss" scoped>
.menu {
    min-height: 500px;
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /*  .menu_header {
    } */
    .menu_items {
        transition: height 0.6s ease-out;
        padding-top: 50px;
        width: 80%;
        ul {
            margin: 0px;
            padding: 0px;
        }
        .item {
            .item_wrapper {
                position: relative;
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                flex-direction: column;
                min-height: 100px;
                .spicy {
                    position: absolute;
                    right: 0px;
                    top: -20px;
                    z-index: 100;
                    max-width: 20px;
                }
                .linewrapper {
                    display: flex;
                    align-items: baseline;
                    font-size: 14px;
                    margin-top: 10px;
                }
                .middle {
                    border-bottom: 1px dotted #aaa;
                    flex-grow: 1;
                    margin: 0 5px;
                }
                text-align: left;
                width: 300px;
                .name_fi {
                    font-size: 16px;
                }
                .name_en {
                    font-size: 14px;
                    color: rgb(139, 139, 139);
                }
            }
            @media only screen and (max-width: 620px) {
                .item_wrapper {
                    width: 280px;
                }
            }
            @media only screen and (max-width: 430px) {
                .item_wrapper {
                    width: 240px;
                }
            }
        }
    }
}
</style>
