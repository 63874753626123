
import { computed, defineComponent, reactive, ref, onBeforeMount } from 'vue'
import Header from '@/components/Header.vue'
import Advert from '@/components/Advert.vue'
import Services from '@/components/Services.vue'
import Info from '@/components/Info.vue'
import Lunch from '@/components/Lunch.vue'
import Images from '@/components/Images.vue'
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'

import {
    getBusinessHours,
    getLunch,
    getImages,
    getMenus,
    getStory,
    getContact,
} from '@/services/common'
export default defineComponent({
    name: 'Home',
    components: {
        Header,
        Advert,
        Services,
        Info,
        Lunch,
        Images,
        Menu,
        Footer,
    },
    setup() {
        let loading = ref(true)
        let state = reactive({
            lunchItems: [],
            hours: [],
            images: [],
            menus: [],
            story: {},
            contact: {},
        })
        const lunchMenu = computed(() => state.lunchItems)
        const businessHours = computed(() => state.hours)
        const images = computed(() => state.images)
        const menuItems = computed(() => state.menus)
        const story = computed(() => state.story)
        const contact = computed(() => state.contact)
        onBeforeMount(async () => {
            loading.value = true
            try {
                const lunch = await getLunch()
                const hours = await getBusinessHours()
                const images = await getImages()
                const menus = await getMenus()
                const story = await getStory()
                const contact = await getContact()

                state.lunchItems = lunch
                state.hours = hours
                state.images = images
                state.menus = menus
                state.story = story.story
                state.contact = contact
                loading.value = false
            } catch (error) {
                console.log('Fetching error ', error)
                loading.value = false
            }
        })

        return {
            loading,
            lunchMenu,
            businessHours,
            images,
            menuItems,
            story,
            contact,
        }
    },
})
