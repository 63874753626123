
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ImageBox',
    props: ['url'],
    components: {},
    setup({ url }) {
        const imgUrl =
            process.env.NODE_ENV == 'production'
                ? `${process.env.VUE_APP_PROD_URL}${url}`
                : `${process.env.VUE_APP_DEV_URL}${url}`
        return {
            imgUrl,
        }
    },
})
