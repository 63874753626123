import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/style/main.scss'
import VueGoogleMaps from '@fawmi/vue-google-maps'

createApp(App)
    .use(VueGoogleMaps, {
        load: {
            key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
        },
    })
    .use(store)
    .use(router)
    .mount('#app')
