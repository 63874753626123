
import { defineComponent } from 'vue'
import Foodora from '@/assets/svg/Foodora.svg'
import Wolt from '@/assets/svg/Wolt.svg'
export default defineComponent({
    name: 'Services',
    components: {},
    setup() {
        const services = [
            {
                icon: Foodora,
                name: 'Foodora',
                link: 'https://www.foodora.fi/en/restaurant/s5xs/tung-hing',
            },
            {
                icon: Wolt,
                name: 'Wolt',
                link: 'https://wolt.com/fi/fin/espoo/restaurant/ravintola-tung-hing',
            },
        ]
        const navigate = (link: string) => (window.location.href = link)
        return { services, navigate }
    },
})
