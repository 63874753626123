
import { defineComponent, computed, reactive, onMounted } from 'vue'
import Chili from '@/assets/svg/chili.svg'
export default defineComponent({
    name: 'Menu',
    components: {},
    props: ['menus'],
    setup({ menus }) {
        const state = reactive({
            activeMenuId: '',
        })
        const currentMenu = computed(() => menus.find((menu: any) => menu.id == state.activeMenuId))
        const setMenu = (id: string) => {
            state.activeMenuId = id
        }
        onMounted(() => (state.activeMenuId = menus[0].id))

        return { setMenu, menus, currentMenu, Chili }
    },
})
